import { defineStore } from 'pinia'
import PacientesService from '../services/pacientes.service'
import { useClientesStore } from './clientes.store'
import dayjs from 'dayjs'
const _PacientesService = new PacientesService()
const clientesStore = useClientesStore()

export const usePacientesStore = defineStore('pacientesStore', {
  state: () => ({
    tipos_identificacion: [],
    listado_pacientes: [],
    paciente_seleccionado: '',
    tipo_id_seleccionado: 'CC',
    historia_clinica: [],
    display_editar_paciente: false,
    zonificacion_seleccionada: '',
    localizacion_seleccionada: {},
    parametros_paginado_historia_clinica: {
      offset: 0,
      page: 0,
      limit: 10,
      filtros: {}
    }
  }),
  getters: {
    _tipos_identificacion: state => state.tipos_identificacion,
    _listado_pacientes: state => state.listado_pacientes,
    _paciente_seleccionado: state => state.paciente_seleccionado,
    _historia_clinica: state => state.historia_clinica,
    _display_editar_paciente: state => state.display_editar_paciente,
    _zonificacion_seleccionada: state => state.zonificacion_seleccionada,
    _localizacion_seleccionada: state => state.localizacion_seleccionada,
    _parametros_paginado_historia_clinica: state => state.parametros_paginado_historia_clinica,
    _tipo_id_seleccionado: state => state.tipo_id_seleccionado
  },
  actions: {
    obtenerTiposIdentificacion () {
      _PacientesService.tiposIdentificacion().then(({ data }) => {
        this.tipos_identificacion = data
      })
    },
    obtenerListadoPacientes (cc) {
      return _PacientesService.listarPacientes({ CardCode: clientesStore._cliente_seleccionado.CardCode, ...cc }).then(({ data }) => {
        const array = []
        for (const i of data) {
          let rango = ''
          if (i.U_ACS_Regimen === 'Subsidiado') {
            if (i.U_ACS_Nivel === 'N1' || i.U_ACS_Nivel === 'N2' || i.U_ACS_Nivel === 'N3') {
              rango = i.U_ACS_Nivel === 'N1' ? 'Subsidiado nivel 1' : (i.U_ACS_Nivel === 'N2' ? 'Subsidiado nivel 2' : 'Subsidiado nivel 3')
            } else {
              rango = 'Contributivo - RANGO INCORRECTO'
            }
          } else {
            if (i.U_ACS_Nivel === 'R1' || i.U_ACS_Nivel === 'R2' || i.U_ACS_Nivel === 'R3') {
              rango = i.U_ACS_Nivel === 'R1' ? 'Contributivo rango 1' : (i.U_ACS_Nivel === 'R2' ? 'Contributivo rango 2' : 'Contributivo rango 3')
            } else {
              rango = 'Subsidiado - NIVEL INCORRECTO'
            }
          }
          if (!i.U_ACS_Ciudad_domicilio) {
            i.U_ACS_Ciudad_domicilio = i.U_ACS_Ciudad
            i.U_ACS_Dpto_domicilio = i.U_ACS_Dpto
          }
          i.U_ACS_FchNacim = dayjs(i.U_ACS_FchNacim).format('YYYY-MM-DD')
          array.push(
            {
              ...i,
              name: i.U_ACS_NmrIdentf + ' - ' + i.U_ACS_NmbPct,
              rango_nivel: rango
            }
          )
        }
        this.listado_pacientes = array
        return array
      })
    },
    getHitoriaClinicaPaciente () {
      _PacientesService.listarHistoriaClinica(this._parametros_paginado_historia_clinica).then(({ data }) => {
        this.historia_clinica = data
      })
    },
    actualizarPaciente (body, id) {
      return new Promise((resolve) => {
        _PacientesService.actualizarPaciente(body, id).then((data) => {
          const paciente = this._paciente_seleccionado
          for (const property in body) {
            paciente[property] = body[property]
          }
          this.paciente_seleccionado = paciente
          resolve(data)
        })
      })
    },
    setPaciente (payload) {
      this.paciente_seleccionado = payload
    }
  }
})
